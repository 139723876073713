
import CyanPageWrapper, {
  router,
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";
import cyanRequest from "@/modules/cyanRequest";
import CyanForm from "@/components/CyanForm.vue";
import {
  arrowForwardCircleOutline,
  arrowBackCircleOutline,
  warningOutline,
  eyeOutline,
  eyeOffOutline,
} from "ionicons/icons";

import ValidatorSet, {
  validateDUILogin,
  validateRegexp,
} from "@/modules/cyanValidator";

import {
  IonButton,
  IonItem,
  IonLabel,
  IonIcon,
  IonPage,
  IonInput,
  IonCheckbox,
} from "@ionic/vue";

export default defineComponent({
  name: "TwoFactorConfirm",
  components: {
    CyanPageWrapper,
    CyanForm,
    IonPage,
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonInput,
    IonCheckbox,
  },
  computed: {
    storedDui: {
      get() {
        return store.getters.smsRequestUser;
      },
      set(value: any) {
        store.commit("requestedSMSCode", value);
      },
    },
  },
  data() {
    return {
      smscode: "",
      password: "",
      password2: "",
      remember: false,
      validator: null as any,
      passwordVisible: false,
    };
  },
  mounted() {
    const validarPassword2 = function (_: any, v: string) {
      const password = (document.querySelector(
        ".__twoFactorConfirm-password"
      ) as any).value;
      const isOK = v === password;
      return {
        ok: isOK,
        formatted: v,
        isFull: false,
        reason: isOK ? "" : "Las contraseñas no coinciden.",
      };
    };

    this.validator = new ValidatorSet([
      {
        validator: validateDUILogin,
        element: ".__twoFactorConfirm-DUI",
        nextElement: ".__twoFactorConfirm-code",
      },
      {
        validator: validateRegexp,
        element: ".__twoFactorConfirm-code",
        nextElement: ".__twoFactorConfirm-password",
        validatorParams: {
          regexp: "^......$",
          full: true,
          noMatch: "El código está compuesto de 6 caracteres.",
        },
      },
      {
        validator: validateRegexp,
        element: ".__twoFactorConfirm-password",
        nextElement: ".__twoFactorConfirm-password2",
        validatorParams: {
          regexp: ".....",
          noMatch: "La contraseña debe tener al menos 5 caracteres.",
        },
      },
      {
        validator: validarPassword2,
        element: ".__twoFactorConfirm-password2",
        nextElement: ".__twoFactorConfirm-next",
      },
    ]);
    document
      .querySelector(".__login-next")
      ?.addEventListener("keyup", (ev: any) => {
        if (ev.key == "Enter" || ev.key == " ") this.checkCode();
      });
  },

  setup() {
    return {
      arrowForwardCircleOutline,
      arrowBackCircleOutline,
      warningOutline,
      eyeOutline,
      eyeOffOutline,
    };
  },
  methods: {
    toggleViewPassword() {
      this.passwordVisible = !this.passwordVisible;
    },

    async checkCode() {
      if (this.validator) {
        if (!(await this.validator.checkWithAlert())) return;
      }

      const d = await cyanRequest("setPassword", {
        isModal: true,
        isPost: true,
        needsPreflight: !this.$data.remember,
        params: {
          name: this.storedDui,
          password: this.$data.password,
          password2: this.$data.password2,
          code: this.$data.smscode,
          requestToken: this.$data.remember ? "1" : "",
        },
      });

      if (!d.ok) return;

      store.commit("userLogin", d);
      router.replace("/home");
    },
  },
});
